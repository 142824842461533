
.loginBox {
    display: flex;
    align-items: center;
    height: 75vh;
    width: 70%;
    margin: auto;
    margin-bottom: 100px;
    justify-content: center;
    /* border: 1px solid white; */
    border-radius: 10px;
    /* scale: 3; */
    /* z-index: 1000; */
    /* position: relative; */
    background: black;
    /* border: 0.5px solid white; */
    box-shadow: 0 0 17px -8px white;
}

.videoContainer {
    width: 60%;
    height: 100%;
    overflow: hidden;
    position: relative;
    z-index: 1;
}
.videoContainer video {
    height: 100%;
    z-index: 1;
    border-radius: 10px;
}
.loginCredentials {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    position: relative;
    /* box-shadow: -15px 0 80px 35px black; */
    z-index: 5;
}
.loginCredentials svg{
    width: 75%;
    margin: 1em auto;
}
.login-title {
    fill: black;
    font-size: 750%;
    font-family: avatar;
}


.login-loginFormDiv-form{
    width: 60%;
}

.login-loginFormDiv-form .text_field{
    
    margin: 2em auto;
    border-radius: 10px;    
}

.text_field input{
    width:90%;
    outline: none;
    margin: 10px auto;
    background-color: transparent;
    color: white;
    border: none;
    border-bottom: 3px solid white;
    caret-color: white;
    font-size: 16pt;
    font-family: 'norm';
    transition: 0.1s;
}
.text_field input:focus{
    padding: 5px 0;
    margin: 5px auto;
}
.text_field input:focus::placeholder{
    visibility: hidden;
}
.text_field input::placeholder{
    color: white;
    font-size: 16pt;
    font-family: norm;
}




.loginBtn input {
    padding: 5px;
    border-radius: 10px;
    outline: none;
    border: none;
    font-size: 14pt;
    background-color: #910c0e;
    color: white;
    cursor: pointer;
    font-family: 'norm';
    font-size: 20pt;
    margin: 5px;
}
.loginBtn input:hover
{
    background-color: #ba1113;
}


@media only screen and (max-width: 600px) {
    
    .videoContainer{
        height: 60vh;
        width: 80vw;
    }
    .loginCredentials
    {
        width: 80%;
        height: max-content;
        margin: 10px 0px;
        padding: 10px 0px;
    }
    .loginBox
    {
        height: fit-content;
        width: fit-content;
        display: flex;
        flex-direction: column;
    }
    .login-loginFormDiv-form{
        width: 75%;
    }
  }