
.ru-register{
    
    display: flex;
    align-items: center;
    height: 75vh;
    width: 80%;
    margin: auto;
    margin-bottom: 100px;
    justify-content: center;
    border-radius: 10px;
    background: black;
    box-shadow: 0 0 17px -8px white;
    position: relative;
    overflow-y: scroll;
    scrollbar-width:thin;
    scrollbar-color: rgb(83, 83, 83) rgba(28, 28, 28, 0.248);
    
}

.ru-register-videoContainer{
    width: 40%;
    height: 100%;
    overflow: hidden;
    position: sticky;
    top: 0;
    z-index: 1;
}
.ru-register-videoContainer video{
    height: 100%;
    z-index: 1;
    border-radius: 10px;
    margin: auto;
}

.ru-register-cred{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    position: relative;
}

.ru-register-cred .svg{
    width: 60%;
    margin: 0.5em auto;
}
.svg svg{
    width: 80%;
}
.register-title {
    fill: black;
    font-size: 600%;
    font-family: avatar;
}

.textField-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.ru-register-cred-form label,.ru-register-cred-form option{
    font-family: 'norm';
    font-size: 14pt;
    cursor: pointer;
}
.ru-register-textField{
    margin: 0.5em auto;
    width: 40%;
}
.ru-register-textField input{
    /* padding: 1em;
    width: 100%;
    border-radius: 10px; */
    width:90%;
    outline: none;
    margin: 10px 0;
    background-color: black;
    color: white;
    border: none;
    border-bottom: 3px solid white;
    caret-color: white;
    font-size: 16pt;
    font-family: 'norm';
    transition: 0.1s;
    
}
.ru-register-textField input:focus{
    padding: 5px 0;
    margin: 5px 0;
}
.ru-register-textField input:focus::placeholder{
    visibility: hidden;
}
.ru-register-textField input::placeholder{
    color: white;
    font-size: 16pt;
    font-family: norm;
}



.ru-register-checkbox,.ru-register-select,.ru-register-qr,.ru-register-ss{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px;
}
.ru-register-checkbox input,.ru-register-select select,.ru-register-qr img,.ru-register-ss input{
    margin: 10px;
}
.ru-register-select select{
    padding: 5px;
    background-color: white;
    outline: none;
    border-radius: 5px;
    font-family: 'norm';
    font-size: 14pt;
}

.ru-register-qr img{
    height: 150px;
    width: 150px;

}

.ru-register-ss input#file-upload-button{
    padding: 0.5em;
}

.regBtn input[type="submit"] {
    padding: 5px;
    border-radius: 10px;
    outline: none;
    border: none;
    font-size: 14pt;
    background-color: #910c0e;
    color: white;
    cursor: pointer;
    font-family: 'norm';
    font-size: 20pt;
    margin: 5px;
}
.regBtn input[type="submit"]:hover
{
    background-color: #ba1113;
}
.regBtn input{
    margin: 10px;
}
.regBtn{
    display: flex;
    justify-content: center;
    align-items: center;
}
.regBtn input[type="reset"]{
    background-color: transparent;
    color: white;
    border: none;
    font-family: 'norm';
    text-decoration: underline;
    font-size: 14pt;
    cursor: pointer;
}

/* Phone view */
@media only screen and (max-width: 600px){
    .ru-register{
        height: max-content;
        width: max-content;
        display: flex;
        flex-direction: column;
        min-height: max-content;
    }
    .ru-register-cred{
        width: 90vw;
        height: max-content;
        margin: 10px 0px;
        padding: 15px 5px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.449), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
    }
    .ru-register-videoContainer{
        height: 60vh;
        width: 80vw;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.449), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
    }
    .ru-main{
        min-height: max-content;
    }
    .textField-container{
        flex-direction: column;
    }
    .ru-register-textField{
        width: 80%;
    }
    .ru-register-textField input{
        background-color: rgba(76, 76, 76, 0.311);
        padding: 5px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    .ru-register-textField input::placeholder{
        text-align: left;
    }
    /* .regBtn{
        flex-direction: column;
    } */
    
}

