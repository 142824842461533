*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}
::selection {
    border-radius: 5px;
    background-color: whitesmoke; /* Orange background */
    color: #910c0e; /* White text color */
  }
  img,video{
    user-select: none;
  }
NavLink{
    background-color: transparent;
}
body,html{
    overflow-x: hidden;
    scroll-behavior: smooth;
}
.no-scroll {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}
p,a,button,li,td{
    font-family: norm;
    font-weight: bold;
    font-size: 20pt;
    margin: 5px 0;
}
a{
    text-decoration: none;
    color: white;
}
h1,h2,h3,h4,h5,h6,th{
    font-family: avatar;
    margin: 15px 0;
}
h1{
    font-size: 34pt;
}
h2{
    font-size: 32pt;
}
h3{
    font-size: 30pt;
}
h4{
    font-size: 28pt;
}
h5{
    font-size: 26pt;
}
h6{
    font-size: 24pt;
}
th{
    font-size: 18pt;
}

@font-face {
    font-family: Rapier-Zero;
    src: url('../public/webPage resorces/RapierZero-325M.ttf');
}
@font-face {
    font-family: norm;
    src: url('../public/webPage resorces/SingleDay-Regular.ttf');
}
@font-face {
    font-family: avatar;
    src: url('../public/webPage resorces/Avatar Airbender.ttf');
}

@media (max-width: 768px) {
    h1{font-size: 28pt;}
    h2{font-size:26pt;}
    h3{font-size: 24pt;}
    h4{font-size: 22pt;}
    h5{font-size: 20pt;}
    h6{font-size: 18pt;}
    th{font-size: 16pt;}
    p,a,button,li,td{
        font-size: 16pt;
        margin: 5px 0;
    }
    h1,h2,h3,h4,h5,h6{
        font-family: avatar;
        margin: 15px 0;
    }

}
@media (max-width:480px) {
    h1,h2,h3,h4,h5,h6{
        margin: 5px auto;
    }
    h1{font-size: 22pt;}
    h2{font-size:20pt;}
    h3{font-size: 18pt;}
    h4{font-size: 16pt;}
    h5{font-size: 14pt;}
    h6{font-size: 12pt;}
    th{font-size: 14pt;}
    p,a,button,li,td{
        font-size: 12pt;
        margin: 5px 0;
    }
    h1,h2,h3,h4,h5,h6{
        font-family: avatar;
        margin: 15px 0;
    }
}

.section-heading{
    color: #d57511;
}
#body{
    width: 100%;
    padding: 10px 2em;
    /* color: #ffffdd; */
    color: white;
    background-color: black;
    position: relative;
    min-height: 100vh   ;
}

/*Written By Pratheek */
/* Loading wheel for data load waiting time */
.loading-png{
    width: 120px;
    height: 120px;
    animation: rotation 2s infinite linear;
}
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.popup{
    display: none;
  }
  .popup-display{
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(25, 25, 25, 0.732);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  .popup-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: black;
    padding: 15px;
    border-radius: 10px;
    position: relative;
    animation: 0.01s zoomIn 1;
  }
  @keyframes zoomIn {
    0%{
        height: 0;
        width: 0;
    }
    50%{
        height: fit-content;
        width: fit-content;
    }
  }

/****************** Layout ********************/


header{
    height: 130px;
}

#nav{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
}
.solid{
    background-color: black;
}
.transparent{
    background-color: rgba(0, 0, 0, 0);
}
.container{
    display: none;
}

#sword{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}
.nav-items{
    position: relative;
    width: 75%;
    height: 100%;
    

}
#nav-links{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* align-items: center; */
    width: 60%;
    position: absolute;
    top: 30%;
    right: 5%;

}
.nav-logo{
    display: none;
}
#nav-links a{
    text-decoration: none;
    color: #910c0e;
    font-weight: 600;
    padding: 7px;
}
#nav-links a:hover{
    border-top: 5px solid #910c0e;
}

.signIn{
    padding: 8px;
    margin-right: 10px;
    text-decoration: none;
    color: #e2e2e2;
    font-weight: 600;
    /* border: 3px solid #e2e2e2; */
    border-radius: 20px;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    /* box-shadow: 0 0 15px 1px #910c0e;; */
    font-size: 16pt;
    padding-right: 0;
}
.circles{
    width: 10%;
  }


.site-footer {
    background-size: cover;
    background-position: center;
    padding: 1em 2em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 0px 0px 13px 0px #ff00004d;
    border-radius: 116px;
}

.footer-content {
    position: relative;
    max-width: 800px;
    padding: 0 20px;
    color: white;
}

.site-footer h2 {
    margin-bottom: 20px;
}

.social-icons {
    margin-top: 20px;
}

.social-icons a {
    text-decoration: none;
    color: white;
    margin-right: 20px;
}

.social-icons img {
    width: 30px;
    height: 30px;
    vertical-align: middle;
}



/*////////////// Layout //////////////////*/

/*************** Home Page ***************/

#homebody{
    width: 90%;
    margin: 0 auto;
}

.hero{
    display: flex;
    width: 100%;
    padding: 0 0.5em;
    margin: 0 auto 50px auto;
}
.background-gradient{
    position: absolute;
    top: 0;width: 100%;
    height:100%;
    background: linear-gradient(to top,black,rgb(0,0,0,0) 25%,rgb(0,0,0,0) 75%,black);
}

.left{
    width: 50%;
}
.left img{
    width: 100%;
}
.right{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


#hero{
    /* background: url(../public/webPage\ resorces/onePiecePosterBG.jpg); */
    background: url(../public/webPage\ resorces/one_piece_shadow_of_tony_zoro_nami_with_red_background_hd_anime.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 125px 0;
    height: 100vh;
    width: 100%;
    position: relative;

}
#hero *{
    color: white;
}

#title{
    text-align: center;
    font-family: Rapier-Zero;
    font-weight: 100;
    font-size: 8vw;
}


.time {
    height: 40%;
    width: 80%;
    margin: 2.5% auto;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    z-index: 2;
    position: relative;

}

.timer{
    font-size: 6vw;
    font-weight: bold;
    transition: 0.5s;
}
.colon::after{
    content: ':';
    margin-left: 20%;
    animation: blink 1s ease-in-out infinite;
}
@keyframes blink {
    0%{
        opacity:1;

    }
    75%{
        opacity:0;
    }
    
    
}
.hero-event-details{
    text-align: center;
    z-index: 2;
    position: relative;
    width: fit-content;
    margin: auto;
}
.heading{
    font-size: 2.5vw;

}
#theme{
    display: flex;
    justify-items: center;
    align-items: center;
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 70vh;
    padding: 2em 0;
    margin-bottom: 50px;
}
#theme::after,#theme::before{
    content: "";
    height: 100%;
    width: 10%;
    background-color: white;
    position: absolute;
    z-index: 1;
}
#theme::after{
    right: 0;
    background: linear-gradient(to right,rgba(255,255,255,0)30%,rgb(0, 0, 0));
}
#theme::before{
    left: 0;
    background: linear-gradient(to left,rgba(255,255,255,0),black);
}

.scroll{
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: scroll 20s infinite linear;
    height: 100%;
    opacity: 0.8;

}
@keyframes scroll {
    from{

        transform: translateX(0);
    }
    to{
        transform: translateX(-100%);
    }
    
}
#theme div img{
    height: 100%;
    margin: 10px;
    scale: 0.9;
}
.theme-text{
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 100%;
    /* background: linear-gradient(360deg, black 45%, #000000cc, transparent); */
    background-color: rgba(0, 0, 0,0.7);
    z-index: 2;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}


#events{
    margin-bottom: 100px;
    padding: 1em 0;
}
.event-list{   
    margin: 1.5em auto;
}
.event-buttons{
    display: flex;
    /* flex-wrap: wrap; */
    justify-content:center;
    align-items: center;
    padding: 1em 0;
    margin: 1.5em auto;
    min-height: 100px;
    /* position: sticky;
    top: 150px;
    z-index: 10000; */
}
.eBtn{
    background-color: transparent;
    border: 0;
    box-shadow: 0px 4px 5px 0 white;
    color: white;
    padding: 10px 30px;
    font-family: 'norm';
    font-size: 16pt;
    cursor: pointer;

}
.eBtn:hover{
    background-color: white;
    color: black;
}

.eBtn span{
    font-family: 'norm';
}

.active{
    background-color: white;
    color: black;
}


  @media only screen and (max-width: 600px) {
    .eBtn{
        padding: 5px 10px;
        font-size: 14pt;
    }

  }


.card-container{
    display: flex;
    overflow: hidden;
    overflow-x: scroll;
    scrollbar-width:thin;
    scrollbar-color: rgb(83, 83, 83) rgba(28, 28, 28, 0.248);
    width: 100%;
    height: fit-content;
    align-items: center;
    padding: 1em 0;
}
.card{
    height:400px;
    width: 300px;
    border-radius: 15px;
    margin: auto 1.5em;
    position: relative;
    flex-shrink: 0;
    text-align: center;
    border-radius: 15px;
}
.card-image{
    height: 60%;
    position: absolute;
    top: 0;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-color: rgba(29, 29, 29, 0.427);
    transition: ease-in 0.5s;
}
.card-hover{
    position: absolute;
    bottom: 0;
    height: 0;
    transition: 0.5s;
    width: 100%;
    background-color: rgba(29, 29, 29, 0.427);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 25% 0 0 0;
    visibility: hidden;
}
.card-info{
    height: 40%;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgba(73, 73, 73, 0.427);
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    color: white;
}
.card-info h6{
    font-size: 20pt;
    margin: 0;
    
}
.card-info p{
    font-size: 12pt;
}
.card-view-more{
    padding: 10px;
    border: 3px solid white;
    border-radius: 10px;
    display: none;
    backdrop-filter: blur(10px);
    color: white;
}

.card:hover .card-image{
    height: 100%;
    background-color: rgba(73, 73, 73, 0.427);
}

.card:hover .card-hover{
    height: 100%;
    visibility: visible;
}

.card:hover .card-info{
    background-color: rgba(0,0,0,0)
}
.card:hover .card-view-more{
    display: block;
    
}
.card:hover .card-info h6{
    font-size: 24pt;
    padding: 10px;
    border-bottom: 3px solid white;
}


#map{
    height: 75vh;
    margin-bottom: 200px;
}
#map iframe{
    width: 100%;
    height: 100%;

}

#sponsors{
    height: 60vh;
    border: 1px solid yellow;
    margin-bottom: 100px;
}



#host{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(../public/webPage\ resorces/clgJPG.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 2.5em;
    position: relative;
    margin-bottom: 100px;
}
.logos{
    width: 60%;
    max-width: 300px;
}

.host-section{
    width: 100%;
    margin: 2em auto;
    text-align: center;
    padding: 1.5em;
    border-radius: 10px;
    backdrop-filter: blur(4px);
    background-color: rgba(255, 255, 255, 0.3); 
    color: black;
}

#contact{
    width: 100%;
    height: auto;
    padding: 15px;
    border-radius: 10px;
    text-align: center;
    position: relative;
    margin-bottom: 50px;
    
}
.clip{
    position: absolute;
    width: 200px;
}

#contact *{
    margin: 8px;
    
}

.contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.students{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.dp{
    width: 125px;
    height: 125px;
    border: 3px solid rgb(140 15 9 / 40%);
    border-radius: 100%;
    overflow:hidden;
    display: flex;
    align-items: center;
    justify-content: center;

}

.dp img{
    width: 100%;
    height: 100%;
    margin: 0;
    position: relative;
}

/*//////////// Home Page End ///////////////*/

/************* Event Page ******************/
.event-nav{
    padding: 0 2em;
    position: relative;
    height: fit-content
    
}
#prev,#next{
    background-color: transparent;
    color: whitesmoke;
    position: absolute;
    top: 0;
}
#prev{
    left:0;
}
#next{
    right: 0;
}
#next:hover,#prev:hover{
    transform: scale(1.1);
}

.event-div{
    width: 100%;
    height: 80vh;
    margin-bottom: 100px;
}
#event{
    display: flex;
    /* align-items: center;    
    justify-content: center; */
    height: 100%;
    position: relative;
    overflow-y: scroll;
    scrollbar-width:thin;
    scrollbar-color: rgb(83, 83, 83) rgba(28, 28, 28, 0.248);
}
#event-img-holder{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    width: fit-content;
    width: 40%;  
    height: 100%;
    position: sticky;
    top: 0;
}
#event-img-holder img{
    max-height: 100%;
    max-width: 100%;
}



#event-name{
    width: 70%;
    position: relative;
}
#event-name svg{
    width: 100%;
    height: auto;
}


.event-title{
    fill: black;
    /* font-size:225%; */
    font-size: 375%;
    font-family: avatar;
}
#event-title-common-name{
    position: absolute;
    bottom: -10px;
    right: -10px;
    font-family: 'norm';
    font-size: 16pt;
}


#event-details{
    
    padding: 2em;
    display: flex;
    flex-direction: row;
    width: 97%;
    justify-content: space-between;
    align-items: center;

}
#event-details p{
    margin: 15px;
}


.register{
    padding: 10px;
    background-color: #e2e2e2;
    color: #910c0e;;
    text-decoration: none;
    border-radius: 15px;
    height: fit-content;
    width: fit-content;
    border: 1px solid white;
    cursor: pointer;
    font-size: 16pt;
    
}



#description{
    width: 60%;
    height: fit-content;
    padding: 20px 30px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
#rules h2{
    margin: 15px;
    border-bottom: 3px solid white;
    width: fit-content;
}


#rules ul{
    list-style-type: none;
  }
  
 #rules ul li {
    position: relative; 
    padding-left: 2em; 
    font-weight:100;
    margin: 10px 5px;
  }
  
  #rules ul li::before {
    content: '';
    position: absolute; 
    left: 0; 
    top: 50%; 
    transform: translateY(-50%); 
    width: 1.2em; 
    height: 1.2em; 
    background-image: url('../public/webPage resorces/bullet2.png'); 
    background-size: contain;
  }


/*//////////////////////////////// Event Page  ////////////////////////*/
/******************************** Dashboard ****************************/

/* Pratheek */
.joinWhatsapp{
    border: 1px solid white;
    border-radius: 8px;
    padding: 10px;
    width: fit-content;
}
.joinWhatsapp:hover{
    box-shadow: -1px 2px 8px 0px #f5f5f596;
}
.joinWhatsapp-data{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.joinWhatsapp-data h6{
    font-size: 16pt;
}
.joinWhatsapp-data p{
    font-size: 14pt;
}
.joinWhatsapp-data-img{
    margin-right: 20px;
}
/* Pratheek End */


#dashboard-section{
    margin-bottom: 100px;
    color: #ffffdd;
}
.dashboard-nav{
    display: flex;
    justify-content: space-between;
    padding: 5px 2em;
}

.dashboard-heading{
    width: 70%;
    margin: auto;
    color: #d57511;
}
.dashboard-heading hr{
    color: #d57511;
    border: 1px solid #3b1e00;
}
.details-table{
    width: fit-content;
    margin: 2em auto;
    text-align: center;
    border-collapse: collapse;
    border-style: hidden;
    width: 50%;
}
.dashboard-ol{
    text-align: justify;    
}

.details-table td, .details-table th{
    border-right: 2px dashed #3b1e00;
}
.events th{
    border-bottom: 2px dashed #3b1e00;
}
.details-table *{
    padding: 15px;
    margin: 5px;

}
.event-list-registration{
    width: 60%;
    margin: auto;
}


.toggle-nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#toggle-container {
    margin: 20px;
  }
  .toggle-nav label{
    font-size: 20pt;
    color: white;
  }


  #toggle {
    position: relative;
    appearance: none;
    -webkit-appearance: none;
    width: 50px;
    height: 20px;
    background-color: #333;
    border-radius: 20px;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  #toggle:hover{
    background-color: #414141;
  }
  #toggle:checked {
    background-color: #c21010;
  }

  #toggle::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s;
  }

  #toggle:checked::before {
    transform: translate(26px, -50%);
  }
  #add{
    padding: 5px 15px;
    font-size: 20pt;
    background-color: rgb(27, 27, 27);
    border-radius: 5px;
    width: fit-content;
    margin: auto;
    display: none;
  }
  #add:hover{
    background-color: #414141;
  }

/*/////////////////////////////// Dashboard End  //////////////////////*/
 /* **********************Guidelines Page*********************************************** */
 #guidelines{
    width: 85%;
    margin: auto auto 50px auto;
    box-shadow: 0 0 5px 0 white;
    padding: 1.5em;
    border-radius: 15px;
  }
  #guidelines ol{
    
    margin-left: 5%;
  }
  #guidelines ol li{
    text-align: justify;
    margin: 10px;
    color: whitesmoke;
    text-shadow: none;
  }

  /*///////////////////////////// Guidelines End /////////////////////////////*/
  /********************************* Chat **************************/
  .chat-section{
    position: fixed;
    bottom: 10%;
    right: 10%;
    z-index: 3;
  }
  .chat-window{
    height: 0;
    width: 0;
    background-color: rgb(30 30 30 / 71%);
    backdrop-filter: blur(10px);
    border-radius: 30px;
    transition: 0.2s;
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
  }

  .chat-header{
    
    width: 100%;
    border-bottom: 1px solid rgb(18 18 18 / 72%);
    box-shadow: 0px 16px 20px 1px rgb(18 18 18 / 72%);
    background-color: rgb(18 18 18 / 72%);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    }
    .chat-dp-div{
        height: 45px;
        width: 45px;
        margin: 0 10px;
        background-color: transparent;
        border-radius: 100%;
    }
    .chat-dp-div img{
        width: 100%;
    }
    .chat-header p{
        margin: 0 10px;
        padding: 0;
    }

    .chat-body{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .chat-body::-webkit-scrollbar {
        width: 10px;
      }
      
      /* Track */
      .chat-body::-webkit-scrollbar-track {
        background-color: rgba(95, 95, 95, 0.351);
        border-radius: 10px;
      }
       
      /* Handle */
      .chat-body::-webkit-scrollbar-thumb {
        background: black; 
        border-radius: 10px;
        
      }
      
      /* Handle on hover */
      .chat-body::-webkit-scrollbar-thumb:hover {
        background: #b30000; 
      }
    .chat-msg{
        position    : relative;
        width: 100%;
        /* min-height: 5%; */
        padding: 10px;
        margin: 10px auto;
    }
    .from-msg,.to-msg{
        width: fit-content;
        max-width: 60%;
        background-color: rgba(19, 19, 19, 0.725);
        padding: 5px 10px;
        border-radius: 10px;
        backdrop-filter: blur(10px);
        box-shadow: 0px 5px 5px 0px #6b6b6b;

    }
    .from-msg p,.to-msg p{
        font-size: 13pt;
        font-weight: 100;
    }
    .from-msg ul,.from-msg ol,.to-msg,ul,.to-msg ol{
        padding-left: 15px;
    }
    .from-msg li,.to-msg li{
        font-size: 12pt;
        font-weight: 100;
    }
    .from-msg{
        float: left;
    }
    .to-msg{
        float: right;
    }

    .chat-input{
        width: 100%;
        /* height: 60px; */
        /* background-color: yellow; */
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        padding: 5px;
        border-top: 1px solid rgb(18 18 18 / 72%);
        /* box-shadow: 0px -16px 20px 1px rgb(18 18 18 / 72%); */
        background-color: rgb(18 18 18 / 72%);
        
    }
    .chat-input form{
        width: 100%;
        padding: 0 0 0 15px;
        display: flex;
        align-items: center ;
        justify-content: space-between;
    }
    .chat-input form input[type="text"]{
        width: 75%;
        background-color: transparent;
        caret-color: white;
        padding: 2px 10px;
        border:none;
        color: white;
        font-family: 'norm';
        font-size: 14pt;
        border-bottom: 3px solid white;
        transition: 0.2s;
    }
    .chat-input form input[type="text"]:focus{
        outline: none;
        padding-bottom: 8px;
    }
    .chat-input form input[type="text"]::placeholder{
        color: rgb(94, 94, 94);
        font-weight: bold;
    }
    .chat-input form input[type="submit"]{
        
        font-size: 30pt;
        width: 50px;
        height: 50px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        color: white;

    }

  .chat-icon{
    width: 80px;
    height: 80px;
    border-radius: 100%;
    position: absolute;
    bottom: -50px;
    right: -50px;
    background-color: black;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
  }
  .chat-icon img{
    /* height: 70%; */
    width: 100%;
    position: relative;
    z-index: 12;
  }
  .chat-icon p{
    position: absolute;
    /* z-index: 13; */
    width: max-content;
    right: 90%;
    /* margin: 10px; */
    background: black;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 5px;
  }

  .chat-open{
    height: 60vh;
    width: 30vw;
    max-height: 500px;
    max-width: 350px;
  }
  @keyframes loading {
    0% { opacity: 0; }
    25% { opacity: 1; }
    50% { opacity: 0; }
    75% { opacity: 1; }
    100% { opacity: 0; }
}

.loading-dots .dot {
    animation: loading 1s infinite;
}

.loading-dots .dot:nth-child(2) {
    animation-delay: 0.25s;
}

.loading-dots .dot:nth-child(3) {
    animation-delay: 0.5s;
}


  .chatcross{
    width: fit-content;

  }
.chatcross .bar1,.chatcross .bar3{
    width: 35px;
}



.bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: white;
    border-radius: 10px;
    margin: 6px 0;
    transition: 0.4s;
    }

.change{
    position: relative;
    z-index: 5;
}
.change .bar1 {
transform: translate(0, 11px) rotate(-45deg);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
transform: translate(0, -11px) rotate(45deg);
}

/******************************** Schedule Page ***********************/

.schedule-div{
    width: 70%;
    margin: auto;
    margin-bottom: 100px;
}
.schedule-div table{
    width: fit-content;
    max-width: 800px;
    margin: auto;
    text-align: center;

}
.schedule-div table tr{
    border-radius: 10px;
    padding: 5px !important;
    margin: 10px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.schedule-div table tr td{
    width: 85%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    /* background-color: rgba(49, 49, 49, 0.547); */
    padding: 10px;
    /* border-radius: 10px; */
    border-left: 3px solid white;
}
#table-time-heading{
    width: 15%;
}
#table-events-heading{
    width: 85%;
}
.schedule-div table tr th{
    width: 15%;
    font-size: 22pt;
}
.schedule-event{
    padding: 10px;
    margin: 5px;
    background-color: rgb(48, 48, 48);    
    border-radius: 5px;
    font-size: 16pt;
    position: relative;
    cursor: pointer;
}
.event1 {
    background-color: rgb(139, 0, 0); /* Dark Red */
  }
  
  .event2 {
    background-color: rgb(0, 0, 128); /* Navy */
  }
  
  .event3 {
    background-color: rgb(255, 165, 0); /* Orange */
  }
  
  .event4 {
    background-color: rgb(77 72 167); 
  }
  
  .event5 {
    background-color: rgb(255, 0, 214) /* Dark Cyan */
  }
  
  .event6 {
    background-color: rgb(139, 69, 19); /* Saddle Brown */
  }
  
  .event7 {
    background-color: rgb(49, 0, 49); /* Dark Magenta */
  }
  
  .event8 {
    background-color: rgb(128, 128, 0); /* Olive */
  }
  
  .event9 {
    background-color: rgb(34, 139, 34); /* Forest Green */
  }
  
  .event10 {
    background-color: rgb(128, 0, 0); /* Maroon */
  }
  
  .event11 {
    background-color: rgb(0, 128, 128); /* Teal */
  }
  
  .common-col{
    background-color: white;
    color: black;
  }
  

.schedule-event:hover{
    background-color: black;
    color: white;
}
.schedule-event:hover::after {
    content: attr(data-tooltip); 
    position: absolute;
    background-color: rgb(245, 245, 245);
    color: black;
    padding: 5px;
    width: max-content;
    margin: 5px;
    border-radius: 5px;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14pt;
    white-space: pre-line; 
    z-index: 1;
    box-shadow: 0 0 10px 5px black;
  }



/*//////////////////////////////// Schedule End ///////////////////////*/




/****************************** Tablet Mode *************************/
  @media (max-width: 768px){
    .nav-items{
        display: none;
    }
    #sword{
        display: none;
    }
    .nav-items.show{
        display: block;
        position: fixed;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgb(255 255 255 / 75%);
        transition: 1s;
        backdrop-filter: blur(10px);

    }
    #nav-links{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: unset;
        width: 100%;
        height:100%;
    }
    .nav-logo{
        display: block;
        height: 150px;
        width: 150px;
    }
    #nav-links a{
        /* color: white; */
        font-size: 18pt;
        font-weight: 900;
        margin: 10px auto;
        transition: 5s;
        text-shadow: 1px 1px 3px white;
    }
    header{
        height: 100px;
    }
    #redflame{
        height: 85px;
    }
    .container {
        display: inline-block;
        cursor: pointer;
        margin-left: 5%;
        z-index: 2;
        position: relative;
    }
      
    
    
    
    .card{
        height: 60vh;
        width: 80vw;
        margin: 2em auto;
    }
    .card-container{
        flex-direction: column;
        overflow: hidden;
    }

    .dashboard-heading{
        width: fit-content;
    }
    .details-table *{
        padding: 10px;
    }
    .details-table{
        width: 85%;
    }

    .schedule-div{
        width: 100%;
        
    }
    .schedule-div table tr td{
        width: 100%;
    }
    .schedule-div table tr th{
        width: fit-content;
    }
    #table-time-heading{
        width: fit-content;
    }

    #table-events-heading{
        width: 100%;
    }
    .chat-section{
        right: 15%;
    }
    .chat-open{
        height: 70vh;
        width: 80vw;
    }
    .chat-icon{
        width: 65px;
        height: 65px;
        bottom: -25px;
        right: -25px;
    }


}




/*/////////////////// Tablet Mode End /////////////////////////////*/

 /******************* Mobile View **********************************/

 @media (max-width: 480px){

    #body{
        padding: 5px;
    }

    #nav{
        height: 80px;
    }
    header{
        height: 100px;
    }
    #homebody{
        margin: auto;
    }
    .hero{
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: fit-content;
    }
    .left{
        width: 100%;
    }
    .right{
        width: 100%;
    }
    .title,.timer{
        font-size: 10vw;
    }
    .theme-text p{
        font-size: 14pt;
        text-align: center;
    }
    
    #host{
        padding: 2em 0;
    }
    .host-section{
        margin: 1em auto;
        padding: 0;
        backdrop-filter: blur(2px);
        background-color: rgb(255 255 255 / 25%);
    }
    .students{
        flex-direction: column;
    }
    .site-footer{
        padding: 10px;
        width: 95%;
        margin: auto;
        border-radius: 30px;
        background-color: black;
        margin-bottom: 5px;
    }
    .circles{
        margin: 10px;
    }
    .footer-content{
        padding: 0;
    }


    .event-div{
        height: fit-content;
    }
    #event{
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 10px;
    }
    #event-img-holder{
        width: 100%;
        height: 50vh;
        overflow-y: hidden;
    }
    .card{
        margin: 10px auto;
    }
    #description{
        width: 100%;
        height: fit-content;
        padding: 10px;
        background-color: black;
        box-shadow: -1px -12px 20px 20px black;
        
    }
    #event-name{
        width: 100%;
    }

    .dashboard-nav{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }
    .joinWhatsapp{
        margin: auto;
    }

    .details-table td{
        word-wrap: break-word;
    }
 }


 /* loading icon */
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top:5px solid #3498db;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


 

 /*//////////////////////////////// Mobile View /////////////////////////*/