.np-main{
    height: 100vh;
    display: flex;
    justify-content: center;
    background-color: black;
    align-items: center;
    width: 100%;
    color: white;
}
.np-main-center{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
/* .np-main-img{
    position: relative;
} */
.np-main-img img{
    position: relative;
    filter: invert(0.8);
    animation: 1s floatSkull infinite;
}

#safe-home{
    margin-top: 15px;
    border: 1px solid white;
    color: white;
    background: none;
    padding: 20px;
    border-radius: 20px;
    outline: none;
    transition: 1s;
}
#safe-home:hover a{
    transition: 1s;
}
#safe-home:hover{
    background-color: white;
}
#safe-home:hover a{
    color: #910c0e;
}

@keyframes floatSkull {
    0%{
        right: 40px;
    }
    10%{
        left: 50px;
    }
    20%{
        right: 20px;
    }
    30%{
        left: 10px;
    }
    40%{
        right: 0px;
    }
}

@media only screen and (max-width: 600px) {
    #safe-home{
        background-color: white;
    }
    #safe-home a{
        color: #910c0e;
    }
    .np-main-img img{
        width: 75vw;
    }
  }